<template>

  <div>
    <el-form :inline="true" :model="query" class="query-form" size="mini">

      <el-form-item class="query-form-item">
        <el-select v-model="query.module" placeholder="状态">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="运营配置" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button-group>
          <el-button type="primary" icon="el-icon-refresh" @click="onReset"></el-button>
          <el-button type="primary" icon="search" @click="onSubmit">查询</el-button>
          <el-button type="primary" @click.native="handleForm(null,null)">新增</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-scrollbar class="table-scrollbar" wrap-style="overflow-x: hidden;">
      <el-table
          v-loading="loading"
          :data="list"
          style="width: 100%;">
        <el-table-column
            label="ID"
            prop="id"
            fixed>
        </el-table-column>
        <el-table-column
            label="模块">
          <template slot-scope="scope">
            <div>{{ scope.row.module | moduleFilterName }}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="配置key"
            prop="key">
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            label="值"
            prop="value">
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            label="描述"
            prop="describe">
        </el-table-column>
        <el-table-column
            label="最后更新时间"
            prop="updateTime">
          <template slot-scope="scope">
            <span>{{ scope.row.modified_time | parseTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click.native="handleForm(scope.$index, scope.row)">编辑
            </el-button>
            <el-button type="text" size="small" @click.native="handleDel(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <el-pagination
        :page-size="query.limit"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="total">
    </el-pagination>

    <!--表单-->
    <el-dialog
        :close-on-click-modal="false"
        :title="formMap[formName]"
        :visible.sync="formVisible"
        :before-close="hideForm"
        width="85%"
        top="5vh">
      <el-form :model="formData" :rules="formRules" ref="dataForm">
        <el-form-item label="模块" prop="module">
          <el-select v-model="formData.module" :disabled="formName === 'edit'">
            <el-option label="运营配置" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配置key" prop="key">
          <el-input v-model="formData.key" :disabled="formName === 'edit'" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="formData.type">
            <el-radio :label="0" :disabled="formData.type !== 0">文本</el-radio>
            <el-radio :label="1" :disabled="formData.type !== 1">数值</el-radio>
            <el-radio :label="2" :disabled="formData.type !== 2">Json对象</el-radio>
            <el-radio :label="3" :disabled="formData.type !== 3">加密字符串</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="值" prop="value" v-if="formData.type !== 2">
          <el-input v-model="formData.value" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item label="值" v-if="formData.type === 2">
          <div class="value-box">
            <div class="value-left">
              <div class="value-item-label" v-for="(val, key, index) in formValueJson" :key="index">
                {{key}}
              </div>
            </div>
            <div class="value-right">
              <el-input class="value-item" auto-complete="off" v-for="(val, key, index) in formValueJson" :key="index" v-model="formValueJson[key]"></el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="描述" prop="describe">
          <el-input v-model="formData.describe" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="hideForm">取消</el-button>
        <el-button type="primary" @click.native="formSubmit()" :loading="formLoading">提交</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {
  settingList,
  settingSave,
  settingDelete
} from "../../api/system/setting";

const formJson = {
  id: "",
  module: "",
  key: "",
  type: 0,
  value: "",
  describe: "",
};
export default {
  data() {
    return {
      query: {
        module: 0,
        page: 1,
        limit: 20,
      },
      list: [],
      total: 0,
      loading: true,
      index: null,
      formName: null,
      formMap: {
        add: "新增",
        edit: "编辑"
      },
      formLoading: false,
      formVisible: false,
      formData: formJson,
      formValueJson: {

      },
      formRules: {
        module: [
          {required: true, message: "请输入发布者", trigger: "change"}
        ],
        key: [
          {required: true, message: "请输入key", trigger: "blur"}
        ],
      },
      deleteLoading: false
    };
  },
  methods: {
    onReset() {
      this.$router.push({
        path: ""
      });
      this.query = {
        module: 0,
        page: 1,
        limit: 20,
      };
      this.getList();
    },
    onSubmit() {
      this.$router.push({
        path: "",
        query: this.query
      });
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      settingList(this.query)
          .then(response => {
            this.loading = false;
            if (response.code) {
              this.$message.error(response.message);
            }
            this.list = response.data.list || [];
            this.total = response.data.total || 0;
          })
          .catch(() => {
            this.loading = false;
            this.list = [];
            this.total = 0;
          });
    },
    // 隐藏表单
    hideForm() {
      // 更改值
      this.formVisible = !this.formVisible;
      return true;
    },
    // 刷新表单
    resetForm() {
      if (this.$refs["dataForm"]) {
        // 清空验证信息表单
        this.$refs["dataForm"].clearValidate();
        // 刷新表单
        this.$refs["dataForm"].resetFields();
      }
    },
    // 显示表单
    handleForm(index, row) {
      this.formVisible = true;
      this.formData = JSON.parse(JSON.stringify(formJson));
      if (row !== null) {
        this.formData = Object.assign({}, row);
      }
      this.formName = "add";
      if (index !== null) {
        this.index = index;
        this.formName = "edit";
        this.formValueJson = {}
        if (this.formData.type === 2) {
          this.formValueJson = JSON.parse(this.formData.value)
        }
      }
    },
    formSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.formLoading = true;
          let data = Object.assign({}, this.formData);
          if (data.type === 2) {
            data.value = JSON.stringify(this.formValueJson)
          }
          settingSave(data, this.formName).then(response => {
            this.formLoading = false;
            if (response.code) {
              this.$message.error(response.message);
              return false;
            }
            if (this.formName === "add") {
              // 向头部添加数据
              if (response.data && response.data.id) {
                data.id = response.data.id;
                data.modified_time = new Date()
                this.list.unshift(data);
              }
            } else {
              data.modified_time = new Date()
              this.list.splice(this.index, 1, data);
            }
            this.$message.success("操作成功");
            // 刷新表单
            this.resetForm();
            this.hideForm()
          });
        }
      });
    },
    // 删除
    handleDel(index, row) {
      if (row.id) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning"
        })
            .then(() => {
              let para = {id: row.id};
              this.deleteLoading = true;
              settingDelete(para)
                  .then(response => {
                    this.deleteLoading = false;
                    if (response.code) {
                      this.$message.error(response.message);
                      return false;
                    }
                    this.$message.success("删除成功");
                    // 刷新数据
                    this.list.splice(index, 1);
                  })
                  .catch(() => {
                    this.deleteLoading = false;
                  });
            })
            .catch(() => {
              this.$message.error("取消删除");
            });
      }
    }
  },
  filters: {
    moduleFilterName(index) {
      const indexMap = {
        1: "运营",
      };
      return indexMap[index];
    }
  },
  mounted() {
  },
  created() {
    // 将参数拷贝进查询对象
    let query = this.$route.query;
    this.query = Object.assign(this.query, query);
    this.query.limit = parseInt(this.query.limit);
    this.query.module = parseInt(this.query.module);
    // 加载表格数据
    this.getList();
  }
};
</script>

<style lang="less">

.value-box {
  display: flex;
  align-items: center;
}

.value-item-label {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f5f7fa;
  color: #909399;
  padding: 0 10px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  height: 40px;
  & + .value-item-label {
    margin-top: 5px;
  }
}

.el-input-group__prepend {
  width: 300px !important;
  text-align: right;
}

.value-item {
  & + .value-item {
    margin-top: 5px;
  }
  .el-input__inner {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none;
  }
}

</style>
